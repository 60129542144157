<script>
export default {
    data() {
        return {

        };
    },
    props: {
        link: {
            type:String,
            required: true
        },
        file_name: {
            type:String,
            required: true
        },
        hideDeleteButton: Boolean,
    }
};
</script>

<template>
    <div class="container d-flex justify-content-between">
        <a :href="link" target="_blank" class="file_name">{{ file_name }}</a>
        <div class="d-flex align-items-center">
            <i class="ri-download-line icon" @click="$emit('download')"></i>
            <i v-if="!hideDeleteButton" class="fa fa-times-circle icon ml-2" @click="$emit('delete')" style="color: red;"></i>
        </div>
    </div>  
</template>

<style lang="scss" scoped>
    .container {
        width: 100%;
        padding: 1rem;
        background-color: #F2F2F2;
        border-radius: 10px;
    }

    .file_name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
    }

    .icon {
        cursor: pointer;
    }
</style>