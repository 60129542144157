<template>
    <div>
        <b-table striped="striped" head-variant="light" :busy="isBusy" :items="items" :fields="fields" :per-page="perPage" responsive outlined show-empty>
            <template #empty="scope">
                <div class="has-text-centered text-center">{{ scope.emptyText }}</div>
            </template>

            <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
                <slot :name="slotName" v-bind="scope"/>
            </template>

            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                </div>
            </template>
        </b-table>

        <b-pagination
            v-if="totalRows > perPage"
            v-model="current_page"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @page-click="clickBtn"
        >
        </b-pagination>
    </div>
</template> 

<script>
  export default {
    data() {
      return {
        current_page: 1,
      }
    },
    props: {
        perPage: {
            required: true,
            type: Number
        },
        fields:{
            required: true,
            type: Array,
        },
        items: {
            required:true,
            type: Array,
        },
        isBusy: {
            required:true,
            type: Boolean,
        },
        totalRows: {
            required:true,
            type: Number,
        },
        curr_page: {
            type: Number,
        }
    },  
    methods: {
        clickBtn(event, page) {
            if (event.type == "page-click") {
                this.$emit("page-click", page);
            }
        }
    },
    mounted() {
    }
  }
</script>