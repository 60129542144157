<script>
// import axios from "axios";
// import { authHeader } from "../../../../helpers/auth-header";
import baseTable from "@/components/listings/base-table";
import baseTableList from "@/components/listings/base-table-list";
export default {
  components: {
    baseTable,
    baseTableList,
  },
  data() {
        return {
            property_fields: [
                { key: "title",                 "label": "Property Name" },
                { key: "property_type_name",    "label": "Property Type" },
                { key: "purpose_of_use_name",   "label": "Purpose Of Use" },
                { key: "size",                  "label": "Size" },
                { key: "property_title_desc",   "label": "Property Title Description" },
                { key: "property_remarks",      "label": "Property Remark" },
                { key: "property_amenity_remarks", "label": "Property Amenity Remark" },
                { key: "address_line_1",        "label": "Address Line 1" },
                { key: "address_line_2",        "label": "Address Line 2" },
                { key: "postal_code",           "label": "Postal Code" },
                { key: "city",                  "label": "City" },
                { key: "state_id",            "label": "State" },
                { key: "country_id",          "label": "Country" },
            ],
            rental_fields: [
                { key: "tenure",                "label": "Tenure" },
                { key: "rental",                "label": "Rental Fee" },
                { key: "rental_fee_structure",  "label": "Rental Fee Struture" },
                { key: "rental_due_date",       "label": "Rental Due Date" },
                { key: "fee_sharing",           "label": "Rental Fee Allocation" },
                { key: "start",                 "label": "Start Date" },
                { key: "end",                   "label": "End Date" },
                { key: "tenant_pre_terminate",  "label": "Tenant Pre-terminate" },
                { key: "renewable",             "label": "Renewable" },
                { key: "rental_deposit",        "label": "Rental Deposit" },
                { key: "utility_deposit",       "label": "Utility Deposit" },
            ],
            landlord_field: [
                { key: "landlord_first_name",     "label": "Landlord First Name" },
                { key: "landlord_last_name",      "label": "Landlord Last Name" },
                { key: "landlord_ic",             "label": "Landlord IC" },
                { key: "landlord_phone",          "label": "Landlord Phone" },
                { key: "landlord_email",          "label": "Landlord Email" },
                { key: "landlord_address_line_1", "label": "Landlord Address 1" },
                { key: "landlord_address_line_2", "label": "Landlord Address 2" },
                { key: "landlord_postal_code",    "label": "Landlord Postal Code" },
                { key: "landlord_city",           "label": "Landlord City" },
                { key: "landlord_state_name",       "label": "Landlord State" },
                { key: "landlord_country_name",     "label": "Landlord Country" },
            ],
            landlord_business_field: [
                { key: "landlord_company_name",     "label": "Landlord Company Name" },
                { key: "landlord_company_reg",      "label": "Landlord Company Registration No" },
                { key: "landlord_company_address",  "label": "Landlord Company Address" },
            ],
            tenant_field: [
                { key: "tenant_first_name",     "label": "Tenant First Name" },
                { key: "tenant_last_name",      "label": "Tenant Last Name" },
                { key: "tenant_ic",             "label": "Tenant IC" },
                { key: "tenant_phone",          "label": "Tenant Phone" },
                { key: "tenant_email",          "label": "Tenant Email" },
                { key: "tenant_address_line_1", "label": "Tenant Address 1" },
                { key: "tenant_address_line_2", "label": "Tenant Address 2" },
                { key: "tenant_postal_code",    "label": "Tenant Postal Code" },
                { key: "tenant_city",           "label": "Tenant City" },
                { key: "tenant_state_name",     "label": "Tenant State" },
                { key: "tenant_country_name",   "label": "Tenant Country" },
            ],
            tenant_business_field: [
                { key: "tenant_company_name",    "label": "Tenant Company Name" },
                { key: "tenant_company_reg",     "label": "Tenant Company Registration No." },
                { key: "tenant_company_address", "label": "Tenant Company Address" },
            ],
            amenity_field: [
                { key: "title",     "label": "Amenity" },
                { key: "quantity", "label": "Quantity" },
            ],
            price_field: [
                { key: "item",     "label": "Item" },
                { key: "quantity", "label": "Quantity" },
                { key: "price", "label": "Price" },
            ],
            // tenancy_data: [],
            // price_data: [],
            // order_totals: []
        }
    },
    props: {
        current_status: {
            required: false
        },
        tenancy_data: {
            // type: Object,
            required: true
        },
        price_data: {
            type:Array,
            required: true
        },
        order_totals: {
            type:Array,
            required: true
        },
        errors: {}
    },
    async created() {
        // await this.GetDetails()
    }, 
    methods: {
        
    }
}
</script>

<template>
    <div>
        <div class="row mt-2">
            <div class="col-12 col-sm-6 mb-2">
                <baseTable :fields="property_fields" :items="tenancy_data" heading="Property Details" :errors="errors">
                </baseTable>
            </div>
            <div class="col-12 col-sm-6 mb-2">
                <baseTable :current_status="current_status" :fields="rental_fields" :items="tenancy_data" heading="Rental Details" :errors="errors">
                </baseTable>
            </div>
            
            <div class="col-12 mt-3">
                <p class="section__title mb-2">LANDLORD INFO</p>
            </div>
            <div class="col-12 col-sm-6 mb-2">
                <baseTable :fields="landlord_field" :items="tenancy_data" heading="Landlord Details" :errors="errors">
                </baseTable>
            </div>
            <div class="col-12 col-sm-6 mb-2">
                <baseTable :fields="landlord_business_field" :items="tenancy_data" heading="Landlord Company Info" :errors="errors">
                </baseTable>
            </div>

            <div class="col-12 mt-3">
                <p class="section__title mb-2">TENANT INFO</p>
            </div>
            <div class="col-12 col-sm-6 mb-2">
                <baseTable :fields="tenant_field" :items="tenancy_data" heading="Tenant Details" :errors="errors">
                </baseTable>
            </div>
            <div class="col-12 col-sm-6 mb-2">
                <baseTable :fields="tenant_business_field" :items="tenancy_data" heading="Tenant Company Info" :errors="errors">
                </baseTable>
            </div>

            <!-- ameninity -->
            <div class="col-12 mt-3">
                <p class="section__title mb-2">AMENITY INFO</p>
            </div>
            <div class="col-12 col-sm-6 mb-2" v-if="tenancy_data != null">
                <baseTableList :fields="amenity_field" :items="tenancy_data.amenities" heading="Amenity Item">
                </baseTableList>
            </div>
        </div>
        <div class="row mt-2 mb-5">
            <div class="col-12 mt-3">
                <p class="section__title mb-2">PRICING INFO</p>
            </div>
            <div class="col-12">
                <baseTableList :fields="price_field" :items="price_data" heading="Price List">
                </baseTableList>
            </div>
        </div>
        <!-- total info -->
        <!-- <div class="row mt-2 justify-content-end">
            <div class="col-12 col-md-4 col-sm-6 mb-2">
                <table class="ct_bordered_table float-right w-100">
                    <tr v-for="totals in order_totals" :key="totals.id">
                        <th class="table_title w-50 text-right">{{ totals.name }}</th>
                        <td class="w-50 text-right">RM {{ totals.value }}</td>
                    </tr>
                </table>
            </div>
        </div> -->

    </div>
</template>

<style scoped>
.section__title {
    font-family: "Nunito Sans";
    color: #2D3748;
    font-weight: 700;
    font-size: 14px;
}
</style>