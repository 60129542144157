<script>
export default {
    data() {
        return {
            
        };   
    },
    props: {
        fields: {},
        items: {
            
        },
        heading: {}
    },
    mounted() {
    },
    async created() {
    },
    methods: {
        displayObject(val) {
            let itemVal;
            if (val.includes('.')) {
                
                itemVal = val.split('.').reduce((o, p) => o && o[p], this.items);
                return this.mapNull(itemVal);
            }
            
            return this.mapNull(this.items[val]);
        },
        mapNull(val) {
            return val == null ? '-' : val;
        }
    },
};
</script>
<template>
    <table class="tb">
        <tr>
            <th :colspan="fields.length" class="main_heading"><slot name="heading">{{heading}}</slot></th>
        </tr>
        <tr class="content">
            <th v-for="field in fields" :key="field.key">{{ field.label }}</th>
        </tr>
        <tr v-if="items && items.length == 0" class="content">
            <td :colspan="fields.length">No records found</td>
        </tr>
        <tr class="content" v-for="(item, index) in items" :key="index" v-else>
            <td v-for="field in fields" :key="field.key"><slot :name="field.key">{{ mapNull(item[field.key]) }}</slot></td>
        </tr>
    </table>
</template>

<style lang="scss" scoped>
.tb {
    width: 100%;
    table-layout: fixed;

    tr, td, th {
        white-space: pre-wrap;
        border: 1px solid #2D37484D;
    }

    td, th {
        padding: 10px;
    }

    .main_heading {
        background: #E8ECF0;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-weight: 900;
        line-height: 18.2px;
        text-align: left;
    }

    .content {
        th {
            width: 50%;
            font-family: "Nunito Sans";
            font-weight: 600;
            font-size: 14px;
            line-height: 18.2px;
            text-align: left;
            color: black;
        }

        td {
            width: 50%;
            font-family: "Nunito Sans";
            font-size: 14px;
            font-weight: 600;
            line-height: 18.2px;
            text-align: left;
            color: black;
        }
    }
}
</style>