<script>
export default {
    props: {
        
    },

    data() {
        return {
            file_name: 'Choose File',
            form_data: new FormData()
        }
    },

    created() {

    },

    methods: {
        FileSelect() {
            // const formData = new FormData();
            let file_name = "";
            for (var i = 0; i < this.$refs.file_upload.files.length; i++ ){
                let file = this.$refs.file_upload.files[i];
                this.form_data.append('file_upload[' + i + ']', file);
                file_name += file.name + ', ';
            }
            file_name = file_name.substring(0, file_name.length - 2);
            this.file_name = file_name;
        },
        FileUpload() {
            const formData = new FormData();
            for (var i = 0; i < this.$refs.file_upload.files.length; i++ ){
                let file = this.$refs.file_upload.files[i];
                formData.append('file_upload[' + i + ']', file);
            }
            this.$emit('upload', formData);
        }
    }
};
</script>

<template>
    <div class="d-block">
        <div class="custom-file w-100 mb-2 d-block">
            <input type="file" class="custom-file-input" id="file_upload" multiple="multiple"
            aria-describedby="inputGroupFileAddon01" ref="file_upload" @change="FileSelect">
            <label class="custom-file-label" for="file_upload" ref="file_label">{{ file_name }}</label>
        </div>
        
        <div class="btn btn-orange">
            <span class="" id="upload_btn" @click="FileUpload">Upload</span>
        </div>
    </div>
</template>

<style scoped>
    #upload_btn {
        cursor: pointer;
    }
</style>