<script>
export default {
    data() {
        return {
            message: '',
            confirmedCallback: null,
            show: false,
        };
    },
    methods: {
        showModal(message, confirmedCallback) {
            this.message = message;
            this.confirmedCallback = confirmedCallback;
            this.show = true;
        },

        closeModal() {
            this.show = false;
        },
        hideModel() {
            this.$emit('CloseBtn', true);
        }
    },
    props: {
        title: {
            required: true
        }, 
        preventClose: {
            default: false,
        }
    }
}
</script>

<template>
    <b-modal v-model="show" hide-footer @hide="hideModel" :no-close-on-backdrop="preventClose">
        <template #modal-title>
            {{ title }}
        </template>
        <slot name="inputField">
                
        </slot>

    </b-modal>
</template>