<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DetailsTab from "./tabs/details.vue";
import CtcTab from "./tabs/ctc.vue";
import MessageModal from "@/components/modals/message-modal";
import { mapGetters } from 'vuex';
import ConfirmationModal from "@/components/modals/confirmation-modal";

export default {
  //components
  components: {
    Layout,
    PageHeader,
    DetailsTab,
    CtcTab,
    MessageModal,
    ConfirmationModal,
  },

  //data items
  data() {
    const id = this.$route.params.id;
    return {
      id: id,
      title: `Tenancy View`,
      items: [
        {
          text: "Tenancy",
        },
        {
          text: 'View',
          active: true,
        },
      ],
    };
  },

  //api
  async created() {
  },

  methods: {
    // ...mapActions('modal', ['openMessageModal']),
    // openModal() {
    //   this.openMessageModal("Test Modal");
    // }
    redirect() {
      // console.log(this.modelRedirect);
      if (this.modelRedirect.link == 'refresh' && this.modelRedirect.redirect) {
        window.location.reload();
      }
    }
  },
  computed: {
    ...mapGetters('modal', ['modalCallback', 'modalMessage', 'modalMessageOpen', 'modalConfirmOpen', 'modelRedirect']),
  },
  watch: {
    modalMessageOpen(val) {
      if (val ) {
        this.$refs.messageModal.showModal(this.modalMessage)
      }
    },
    modalConfirmOpen(val) {
      if (val) {
        this.$refs.confirmationModal.showModal(this.modalMessage, this.modalCallback);
      } else {
        this.$refs.confirmationModal.closeModal();
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <MessageModal ref="messageModal" @CloseBtn="redirect"/>
    <ConfirmationModal ref="confirmationModal"/>

    <b-tabs content-class="" active>
      <!-- tab 1  -->
      <b-tab title="Details">
        <DetailsTab />
      </b-tab>
      
      <!-- tab 2 -->
      <b-tab title="CTC">
        <CtcTab />
      </b-tab>
    </b-tabs>
  </Layout>
</template>
